<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">Visualizar aviso</h2>
      </b-col>
    </b-row>

    <b-row class="teacher-profile mt-50">
      <b-col>
        <b-row>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Título</label>
              <b-form-input
                v-model="notification.title"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group description>
              <label for="label">Url</label>
              <b-form-input
                v-model="notification.url"
                type="text"
                disabled
              ></b-form-input>
              <a
                v-if="notification.url"
                target="_blank"
                :href="notification.url"
                style="text-decoration: underline; color: #b23066"
                >Acessar Link</a
              >
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group class="required" description>
              <label for="label">Disponível até</label>
              <b-form-input
                v-model="notification.available_until"
                type="date"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Descrição</label>
              <b-form-textarea
                v-model="notification.description"
                type="textarea"
                placeholder
                required
                disabled
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <targets :data="notification" />
      </b-col>
    </b-row>

    <b-row align-v="center" class="mt-5">
      <b-col class="text-left">
        <b-button @click="sendNotifications" class="large blue"
          >Enviar notificações</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button
          class="large blue outline"
          :to="{
            name: 'notifications',
          }"
          >Voltar</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import httpRda from "../../../http-rda.js";
import Targets from "../../../components/Targets.vue";

export default {
  name: "updateEvent",
  components: { Targets },

  data() {
    return {
      formFilled: false,
      notification: null,
      attachment: null,
      file: null,

      breadcrumb: [
        {
          text: "Administração",
          href: "/administracao",
        },
        {
          text: "Avisos",
          href: "/administracao/notifications",
        },
        {
          text: "Visualizar aviso",
          href: "showEvent",
          active: true,
        },
      ],
    };
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
  },

  methods: {
    getEvent() {
      httpRda.get(`/application-notifications/${this.id}`).then((response) => {
        this.notification = response.data.notification;
      });
    },
    async sendNotifications() {
      this.$swal
        .fire({
          title: "Tem certeza que deseja notificar os destinatários?",
          text: "Todos os usuários selecionados com acesso ao app serão notificados!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda.post("/send-notifiables", {
                resource_name: "ApplicationNotification",
                resource_id: this.id,
                target_name: "User",
              });

              const Toast = this.$swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: `Notificações enviadas aos destinatários!`,
              });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel enviar as notificações!",
                footer: "Por favor, tente novamente mais tarde.",
              });
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel notificar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },
  },

  created() {
    this.getEvent();
  },
};
</script>

<style lang="scss">
.teacher-profile {
  .form-group {
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.title-collapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  h5 {
    flex: 1;
  }

  .btn {
    background: transparent;
    border: 0;

    svg {
      font-size: 16px;
      fill: red;
    }
  }
}

.table-students {
  width: 100%;

  td,
  th {
    font-size: 18px;
    width: 50%;
  }

  th {
    padding-bottom: 20px;
  }

  td {
    padding: 20px 0;
  }

  tr + tr {
    border-bottom: 1px solid $grey5;
  }
}

.student-responsible {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  span {
    background-color: #efefef;
    padding: 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.wrap-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  span {
    background-color: #efefef;
    padding: 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.wrap-button {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}

.tag {
  background-color: #68c5b7;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  padding: 0 3px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
